@import url('https://fonts.googleapis.com/css?family=Mukta+Malar');

@font-face {
  font-family: "Geomanist";
  src: url("/fonts/GEOMANIST-BOOK.OTF") format("opentype");
}

@font-face {
  font-family: "Geomanist-Bold";
  src: url("/fonts/GEOMANIST-BOLD.OTF") format("opentype");
}

.ui-pagina-login {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0px;
  padding-top: 100px;
  background-color: #222838 !important;
}

.ui-pagina-login-gradient-img {
  background-image: url("/images/gradiant-bg-3.png");
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 100vh;
  width: 100% !important;
  position: absolute;
  top: -40px;
  left: 0;
}

.ui-title {
  font-family: 'Geomanist' !important;
}

.btn-ui-1 {
  background: #e83c6e !important;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  color: #FFF !important;
  transition: .3s !important;
}

.btn-ui-1:hover {
  opacity: .8;
  color: #FFF !important;
}

.ui.button:disabled,
.ui.buttons .disabled.button,
.ui.disabled.active.button,
.ui.disabled.button,
.ui.disabled.button:hover {
  background: #e83c6e !important;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
}

.btn-ui-1-basic {
  background: transparent !important;
  background-image: none !important;
  border: solid 1px #ea524f !important;
  color: #ea524f !important;
  transition: .3s !important;
}

.btn-ui-1-basic:hover {
  opacity: .6;
  color: #ea524f !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: 1;
}

.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before {
  background-color: #e83c6e !important;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
}

.container-ui {
  padding: 60px 20px;
}

.menu-horizontal a {
  color: #e83c6e !important;
}

.menu-item {
  margin: -14px -7px !important;
  padding: 14px 15px 11px 15px !important;
  border-radius: 6px;
  transition: .3s !important;
}

.menu-link {
  text-transform: uppercase !important;
  font-size: 1em;
  cursor: pointer;
  color: #e83c6e !important;
  font-family: 'Geomanist' !important;
}

.menu-link:hover {
  background-color: #e83c6e3a;
}

.menu-link-icone {
  font-size: 1em;
  cursor: pointer;
  color: #e83c6e !important;
}

.menu-link-drawer {
  font-size: 11pt;
  padding: 16px 18px 15px 18px;
  display: block;
  color: #000 !important;
  font-family: "Geomanist" !important;
}

.menu-link-drawer:hover {
  background-color: #e83c6e3a;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.badge-loja {
  height: 50px;
  margin: 20px 10px 0px 10px;
}

.div-info {
  padding: 40px 20px;
  background-color: #FFF;
  position: relative;
}

.div-section {
  padding: 30px 20px;
  background-color: #FFF;
  position: relative;
}

.div-section img {
  width: 80%;
  max-width: 280px;
}

.div-section h1 {
  margin-top: -10px;
}

.div-section p {
  /* margin-left: 400px; */
  margin-right: 120px;
}

.div-info-icone {
  display: inline-block;
  position: absolute;
  top: calc(50% - 110px);
  left: calc(25% - 110px);
  font-size: 18px;
}

.div-info-icone-normalize {
  top: calc(50% - 35px);
  left: calc(25% - 25px);
  font-size: 18px;
}

.bg-color-primary {
  background: #e83c6e !important;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  color: #FFF;
}

.bg-color-gray {
  background-color: #DDD;
  color: inherit;
}

.div-icone-right {
  padding: 30px 10px;
  margin: 20px 0px;
  background-color: #FFF;
  position: relative;
}

.div-icone-right h1 {
  margin-right: 375px;
  margin-left: 75px;
  margin-top: -10px;
}

.div-icone-right p {
  margin-right: 550px;
  margin-left: 75px;
}

.div-info-icone-right {
  display: inline-block;
  position: absolute;
  top: calc(50% - 110px);
  right: calc(35% - 110px);
}

.rotate {
  transform: rotate(15deg);
}

.rodape-text {
  color: #FFF;
  font-size: 1.1em;
  display: inline-block !important;
  font-family: 'Geomanist', sans-serif;
}

.rodape-link {
  text-decoration: none;
  color: #FFF;
  font-size: 1.1em;
  border-bottom: dashed 1px #FFF !important;
  display: inline-block !important;
  opacity: .8;
  transition: .5s;
  font-family: 'Geomanist', sans-serif;
}

.rodape-link:hover {
  color: #FFF;
  border-bottom: solid 1px #FFF !important;
  opacity: 1;
}

.metade-left {
  width: 30%;
  text-align: left;
  display: inline-block;
  vertical-align: middle !important;
}

.metade-left img {
  width: 80px;
}

.metade-center {
  width: 20%;
  text-align: center;
  display: inline-block;
  vertical-align: middle !important;
}

.metade-right {
  width: 30%;
  text-align: right;
  display: inline-block;
  vertical-align: middle !important;
}

.container-contato {
  width: 45% !important;
  position: relative;
}

.label-contador {
  font-size: 11px;
  color: #999;
  position: absolute;
  bottom: 53px;
  right: 8px;
}

.rodape {
  padding: 60px 20px;
}

.card-x {
  padding: 10px 15px 5px 15px !important;
  margin: auto !important;
  margin-bottom: 50px !important;
  margin-top: 90px !important;
  overflow: hidden !important;
}

.card-login {
  max-width: 440px !important;
}

.card-header * {
  color: #000;
}

.rotate-30 {
  transform: rotate(-30deg);
}

.full-radius,
.full-radius * {
  border-radius: 100px !important;
}

.btn-padded {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

/* Anúncio */
.div-padded-1 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.msg-sucesso-block {
  text-align: center !important;
  padding: 32px 80px 26px 80px !important;
  font-weight: 100;
  font-family: 'Mukta Malar', sans-serif;
}

.msg-sucesso-inline {
  font-size: 10pt;
  padding: 6px 0;
  color: #21ba29 !important;
  font-weight: 100;
  font-family: 'Mukta Malar', sans-serif;
}

.background-dashboard {
  padding: 140px;
  width: 100%;
  background-color: #0c73b8;
  position: absolute;
  left: 0px;
  top: 0px;
  border-radius: 25px;
}

.menu-vertical {
  left: -1px !important;
  position: fixed;
  top: 64px;
  left: 0px;
  height: 100%;
  overflow: auto;
  box-shadow: 1px 0px 4px #AAA;
  width: 280px;
}

.menu-vertical span {
  margin: 0px 0px;
  transition: .3s !important;
  padding: 24px 20px !important;
  font-size: 1.1em;
}

.menu-vertical .menu-vertical-item-especial {
  background: #e83c6e !important;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  color: rgb(252, 252, 252) !important;
  transition: .3s;
}

.menu-vertical .menu-vertical-item-especial:hover {
  opacity: .85;
}

.menu-vertical span:hover {
  background-color: #e83c6e3a !important;
}

.link-menu-vertical {
  padding: 15px 18px;
  display: inline-block;
  margin: auto;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
  border-radius: 10px;
}

.dashboard-card-link {
  width: 100% !important;
  padding: 40px 10px !important;
  font-size: 12pt !important;
  height: 100% !important;
}

.preview-arquivo-upload {
  margin-bottom: 30px !important;
}

.preview-arquivo-upload span {
  opacity: .4;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.preview-arquivo-upload:hover {
  opacity: .7 !important;
}

.form-upload-foto {
  display: block !important;
  margin: auto;
  margin-right: 10px;
  padding: 40px 30px;
  text-align: center !important;
}

.btn-absolute {
  position: absolute !important;
  top: calc(50% - 16.6px);
}

.btn-right {
  position: absolute;
  right: 20px;
  top: calc(50% - 20.5px);
}

.submenu {
  border: none !important;
  margin-bottom: -33px !important;
  margin-top: 20px !important;
}

.submenu2 {
  border: none !important;
  width: 100% !important;
  position: absolute;
  bottom: 0px;
  left: 0;
}

.div-perfil {
  text-align: center;
  margin-bottom: 50px;
}

.status-conta p {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 30px;
  box-shadow: 1px 1px 4px #999;
  margin-top: 5px;
}

.status-conta .status-conta-2 {
  padding: 20px 30px !important;
  max-width: 420px;
}

.video-preview {
  border-radius: 8px;
  box-shadow: 1px 1px 2px #666;
  outline: none;
}

.grid-produtos {
  min-width: 250px !important;
}

.image-preview {
  width: 100% !important;
}

.div-grid-media {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.div-grid-submit {
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin: -15px 0px 0px 0px;
}

.buttonUI-white {
  background-color: #FFF !important;
  color: #0c73b8 !important;
}

.corpo-dashboard,
.corpo-dashboard-mini {
  margin-left: 280px;
  padding: 40px 30px 80px 30px;
  min-height: 92.8vh;
  background-color: rgba(114, 46, 240, .05);
  margin-top: -10px;
}

.corpo-dashboard input::placeholder,
.corpo-dashboard-mini input::placeholder {
  color: #999 !important;
}

.erro-inline {
  font-size: .9em;
  padding: 8px 0 5px 0;
  color: #ce1916 !important;
  font-weight: 500;
  font-family: 'Geomanist', sans-serif;
}

.ui-action-excluir {
  position: absolute;
  right: 0px;
  top: 10px;
  display: inline-block;
  font-size: 12pt;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s !important;
  color: #e83c6e;
}

.ui-action-excluir:hover {
  opacity: .7 !important;
}

.ui-pergunta-action-1,
.ui-pergunta-action-2 {
  position: absolute;
  right: 15px;
  top: 15px;
  display: inline-block;
}

.ui-pergunta-action-2 {
  right: 60px;
  top: 15px;
}

.btn-salvar-pergunta {
  margin-top: 10px !important;
  text-align: right !important;
}

.animated-list-item {
  background-color: #FFF !important;
  border-radius: 6px !important;
  margin-bottom: 15px;
  transition: .2s;
  cursor: pointer;
  padding: 20px !important;
  position: relative !important;
  box-shadow: 0px 0px 2px #666;
}

/* .animated-list-item:hover {
  opacity: 1;
} */

.animated-list-content {
  margin-left: 5px;
  word-wrap: break-word;
}

.label-ui-arquivo {
  padding: 8px 20px;
  border: dashed 1px #e83c6e !important;
  color: #e83c6e !important;
  border-radius: 5px;
  margin-bottom: 5px !important;
  display: inline-block;
  cursor: pointer;
  opacity: .8;
}

.label-ui-arquivo:hover {
  transition: .3s;
  opacity: 1;
}

.div-ui-acao {
  display: inline-block;
  position: absolute;
  /* border: solid 1px red; */
  height: 100%;
  margin-left: 10px;
  align-content: center;
  align-items: center;
}

.ui-resultado-respostas {
  /* background: #e83c6e !important; */
  /* background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important; */
  /* color: #FFF; */
  padding: 14px 20px 11px 20px !important;
}

.ui-resultado-respostas * {
  font-family: 'Geomanist' !important;
}

.ui-resultado-respostas-item {
  float: right;
}

.ui-resultado-respostas-color-1 {
  border-left: 7px solid #009b5a !important;
}

.ui-resultado-respostas-color-2 {
  border-left: 7px solid #0676c0 !important;
}

.ui-resultado-respostas-color-3 {
  border-left: 7px solid #c02206 !important;
}

.ui-resultado-respostas-color-4 {
  border-left: 7px solid #f8cc06 !important;
}

.ui-resultado-respostas-color-5 {
  border-left: 7px solid #7706f8 !important;
}

.ui-resultado-respostas-color-6 {
  border-left: 7px solid #00e673 !important;
}

.ui-input-with-loader {
  position: relative !important;
}

.ui-loader-input {
  /* border: solid 1px red; */
  position: absolute !important;
  left: 87% !important;
  top: 35px !important;
}

.ui-slider {
  -webkit-appearance: none;
  width: 90%;
  height: 2px;
  background: #e83c6e;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  /* max-width: 460px; */
}

.ui-slider:hover {
  opacity: 1;
}

.ui-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 38px;
  border-radius: 50px;
  background: #e83c6e;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  cursor: pointer;
}

.ui-slider::-moz-range-thumb {
  width: 20px;
  height: 38px;
  border-radius: 50px;
  background: #e83c6e;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  cursor: pointer;
}

.ui-input-creditos {
  font-weight: 900 !important;
  font-family: "Geomanist-Bold" !important;
  color: #e83c6e !important;
  font-size: 1.6em !important;
  text-align: center !important;
  padding: 6px 0px 0px 0px !important;
  margin: 0px 5px 0px 0px !important;
  outline: none !important;
  /* border: none !important; */
  width: 100% !important;
  max-width: 120px !important;
  margin-bottom: 20px !important;
}

.ui-pagina-creditos * {
  font-family: "Geomanist" !important;
}

.ui-card-creditos * {
  font-family: "Geomanist-Bold";
}

.ui-destaque-creditos {
  margin-top: 20px;
  font-size: 1.1em;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  color: #FFF;
  padding: 10px;
  border-radius: 5px;
  font-family: "Geomanist";
}

.ui-validade-plano {
  font-size: 1.1em;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  color: #FFF;
  padding: 15px 24px 12px 24px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-family: "Geomanist";
  margin: -22px;
  margin-top: 20px;
}

.ui-validade-plano p {
  font-family: "Geomanist";
  font-weight: 500;
  margin-top: 5px !important;
  margin-bottom: 5px !important;

}

.ui-credit-card {
  width: 100%;
  max-width: 370px;
  height: 230px;
  background: #e83c6e;
  background-image: linear-gradient(45deg, #e83c6e 0%, #ea524f 100%) !important;
  border-radius: 8px;
  margin: auto;
  position: relative;
  color: #FFF;
  text-transform: uppercase !important;
}

.ui-credit-card-numero {
  position: absolute;
  width: 88%;
  bottom: 80px;
  left: 6%;
  font-size: 22pt;
}

.ui-credit-card-bandeira {
  position: absolute;
  min-width: 70px;
  height: 60px;
  top: 20px;
  left: 6%;
  background-color: #DDD;
  border-radius: 5px;
}

.ui-credit-card-bandeira img {
  height: 100%;
  border-radius: 5px;
}

.ui-credit-card-nome {
  position: absolute;
  width: 66%;
  bottom: 25px;
  left: 6%;
  font-size: 17pt;
}

.ui-credit-card-validade {
  position: absolute;
  width: 21%;
  bottom: 25px;
  right: 6%;
  font-size: 17pt;
  text-align: right;
}

.ui-credit-card-cvv {
  position: absolute;
  bottom: 0px;
  right: 6%;
  font-size: 20pt;
  padding: 15px 15px 10px 15px;
  display: inline;
  background-color: #FFF;
  color: #000;
}

.credit-card-flip {
  transition: .8s;
}

.ui-credit-card-black-bar {
  position: absolute;
  width: 100%;
  bottom: 60px;
  left: 0px;
  background-color: #000;
  height: 50px;
}

.credit-card-elements-flip {
  transition: .8s;
  transform: scale(-1, 1);
  position: absolute;
  width: 100%;
  bottom: 80px;
  font-size: 20pt;
  color: #FFF;
}

.credit-card-flip-reverse {
  transition: .8s;
  transform: rotateY(-180deg);
}

.pagina-404 .img-404 {
  position: absolute;
  right: 5%;
  bottom: 0px;
  max-width: 600px;
  width: 80%;
}

.logo-404 {
  width: 90%;
  max-width: 180px;
  margin-bottom: 20px;
}

.mensagem-404 {
  padding: 80px 30px;
  text-align: center;
}

/* #### CHART #### */

.ui-chart-color-1 {
  background-color: #009b5a;
  color: #FFF;
}

.ui-chart-color-2 {
  background-color: #0676c0;
  color: #FFF;
}

.ui-chart-color-3 {
  background-color: #c02206;
  color: #FFF;
}

.ui-chart-color-4 {
  background-color: #f8cc06;
  color: #000;
}

.ui-chart-color-5 {
  background-color: #7706f8;
  color: #FFF;
}

.ui-chart-color-6 {
  background-color: #00e673;
  color: #000;
}

.ui-chart-line {
  border-radius: 5px;
  overflow: hidden;
  max-height: 80px;
  font-family: 'Geomanist' !important;
}

.ui-chart-line-item {
  transition: 1s;
  width: 0%;
  display: inline-block;
  text-align: center;
  padding: 15px 13px 13px 13px;
}

.ui-chart-line-item p {
  margin: 0px;
}

/* AJUDA */

.ui-pagina-ajuda {
  margin-top: 40px;
  padding: 40px 20px 120px 20px;
  position: relative;
}

.ui-pagina-ajuda h1 {
  font-size: 3.2em;
  padding: 10px;
  color: #FFF;
  /* color: #e83c6e; */
  font-family: "Geomanist";
  text-shadow: 1px 1px 4px #e83c6e62;
}

.ui-pagina-ajuda img {
  width: 90%;
  max-width: 320px;
  opacity: .4;
  position: absolute;
  top: 5px;
  left: 20%;
  z-index: -1;
}

.ui-ajuda-accordion {
  margin-top: 70px !important;
}

.ui-ajuda-itens .ui-ajuda-titulo {
  padding: 22px 20px 16px 20px !important;
  line-height: 1.8em !important;
}

.ui-ajuda-itens .ui-ajuda-titulo span {
  font-family: "Geomanist-Bold" !important;
  font-size: 1.4em;
}

.ui-ajuda-itens .ui-ajuda-texto {
  font-family: "Geomanist" !important;
  font-size: 1.3em;
  display: inline-block;
  padding: 0px 10px 10px 10px;
  text-align: justify;
}

.ui-ajuda-div-iframe {
  padding: 0px 10px;
}

.ui-ajuda-div-iframe iframe {
  width: 768px;
  height: 432px;
  box-shadow: 1px 1px 4px #888;
  /* border-radius: 6px; */
}

/* FALE CONOSCO */
.ui-pagina-faleconosco {
  padding: 150px 20px 20px 20px;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  top: 0px;
  padding-top: 100px;
  background-color: #222838 !important;
}

.ui-pagina-faleconosco h1 {
  font-size: 3.2em;
  padding: 40px 10px 10px 10px;
  color: #FFF !important;
  font-family: "Geomanist-Bold";
  position: relative;
}

.ui-faleconosco-itens .ui-faleconosco-titulo {
  font-family: "Geomanist" !important;
}

.ui-faleconosco-segment {
  padding: 50px !important;
}

.ui-crud-container {
  padding: 20px !important;
}

.mensagem-sucesso-email {
  margin-top: 80px !important;
  padding: 50px 20px !important;
  text-align: center !important;
}

.mensagem-sucesso-email h3 {
  font-family: 'Geomanist' !important;
}

.ui-texto-destaque {
  color: #e83c6e !important;
}

@media (max-width: 1024px) {
  .div-icone-right {
    padding: 10px 10px;
    background-color: #FFF;
    position: relative;
  }

  .div-section h1,
  .div-icone-right h1 {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 30px;
    text-align: center;
  }

  .div-section p,
  .div-icone-right p {
    margin-left: 0px;
    margin-right: 0px;
    text-align: center;
  }

  .div-info-icone,
  .div-info-icone-right {
    text-align: center;
    position: static;
    display: block;
    margin: auto !important;
  }

  .div-info-icone img,
  .div-info-icone-right img {
    width: 140px;
  }

  .container-contato {
    width: 100% !important;
  }

  .ui-ajuda-div-iframe iframe {
    width: 640px;
    height: 360px;
  }
}

/* Produto */

.div-imagem {
  width: 100%;
  height: 190px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

.empty-illustration-1 {
  text-align: center;
  margin-top: 100px;
  opacity: .5;
}

.empty-illustration-1 img {
  width: 80%;
  max-width: 480px;
}

.action-ui-manter {
  text-align: right;
}

@media (max-width: 767px) {
  .corpo-dashboard {
    width: 100%;
    right: 0;
    margin-left: 0px;
    padding: 40px 15px;
  }

  .corpo-dashboard-mini {
    width: 100%;
    right: 0;
    margin-left: 0px;
    padding: 25px 5px;
  }

  .pagina-login {
    height: 100%;
    position: relative;
    margin-top: -40px;
  }

  .ui-pagina-login-gradient-img {
    background-repeat: no-repeat;
    background-size: cover;
  }

  .card-x {
    margin-top: 60px !important;
  }

  .metade-left img {
    width: 60px;
  }

  .metade-left {
    width: 30%;
  }

  .metade-right {
    width: 50%;
  }

  .msg-sucesso-block {
    padding: 30px 30px 26px 30px !important;
  }

  .header-busca {
    padding: 45px 15px 35px 15px;
  }

  .menu-vertical {
    display: none;
  }

  .normalize-list {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .submenu {
    margin-bottom: -5px !important;
    padding: 0 !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .submenu * {
    padding-left: 3px !important;
    padding-right: 3px !important;
  }

  .div-perfil {
    text-align: center;
    margin-bottom: 120px;
  }

  .div-botao-media {
    margin: 0px -20px -15px -20px;
  }

  .div-botao-submit {
    margin: 0px -20px 0px -20px;
  }

  .div-img-right {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  .ui-pergunta-action-2 {
    position: static;
    margin-bottom: 15px;
    right: 150px;
    top: 15px;
  }

  .btn-salvar-pergunta button {
    width: 100%;
  }

  .badge-loja {
    height: 40px;
    margin: 20px 5px 0px 5px;
  }

  .action-ui-manter {
    text-align: center;
  }

  .ui-resultado-respostas {
    padding: 30px 20px !important;
    text-align: center;
  }

  .ui-resultado-respostas-item {
    width: 100%;
    float: none !important;
  }

  .card-auth {
    margin-top: -15px !important;
    margin-bottom: 40px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .ui-crud-container {
    padding: 20px 0px !important;
  }

  .ui-pagina-faleconosco {
    padding: 80px 10px 20px 10px !important;
  }

  .ui-pagina-faleconosco::after {
    background-position: -500px 30px;
  }

  .ui-pagina-faleconosco h1 {
    font-size: 2.5em;
  }

  .ui-faleconosco-segment {
    padding: 30px 25px 25px 25px !important;
  }

  .mensagem-sucesso-email {
    margin-top: 20px !important;
  }

  .ui-ajuda-div-iframe iframe {
    width: 100%;
    height: 315px;
  }

  .ui-pagina-ajuda {
    padding: 40px 5px 120px 5px;
  }
}

@media (max-width: 480px) {
  .animated-list-item {
    padding: 20px 15px !important;
  }

  .animated-list-content {
    max-width: 68%;
  }

  .btn-right {
    position: absolute;
    right: 15px;
    top: calc(50% - 17px);
  }

  .ui-credit-card {
    display: none;
  }
}

@media (max-width: 320px) {
  .animated-list-content {
    max-width: 62%;
  }
}

.ui.secondary.pointing.menu .active.item {
  border-color: #e83c6e;
}

.ui.menu .active.item {
  border-color: #e83c6e !important;
  color: #e83c6e !important;
}

.ui.primary.segment:not(.inverted) {
  border-top: 2px solid #e83c6e !important;
}

.ui.cards>.primary.card, .ui.primary.card, .ui.primary.cards>.card {
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 0 0 #e83c6e, 0 1px 3px 0 #d4d4d5;
}

.ui.cards>.primary.card:hover, .ui.primary.card:hover, .ui.primary.cards>.card:hover {
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 0 0 #e83c6e, 0 1px 3px 0 #bcbdbd;
}

.ui.basic.primary.button, .ui.basic.primary.buttons .button {
  box-shadow: 0 0 0 1px #e83c6e inset !important;
  color: #e83c6e !important;
}

.ui.basic.primary.button:active, .ui.basic.primary.buttons .button:active {
  box-shadow: 0 0 0 1px #e83c6e inset !important;
  color: #e83c6e !important;
}

.ui.basic.primary.button:hover, .ui.basic.primary.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #ea524f inset !important;
  color: #ea524f !important;
}

.ui.basic.primary.button:focus, .ui.basic.primary.buttons .button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #ea524f inset !important;
  color: #ea524f !important;
}

.ui.basic.primary.label {
  background: none #fff !important;
  color: #e83c6e !important;
  border-color: #e83c6e !important;
}

.ui.primary.message {
  background-color: #f8e7ff;
  color: #e83c6e;
  box-shadow: 0 0 0 1px #e83c6e inset, 0 0 0 0 transparent;
}

.ui.statistics .primary.statistic>.value, .ui.primary.statistic>.value, .ui.primary.statistics .statistic>.value {
  color: #e83c6e;
}
