.disable-fields-alert {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disable-fields-alert > div {
  text-align: center;
  text-shadow: 0 0 rgba(255, 255, 255, 1);
}

.ui.cards > .violet.card:hover, .ui.violet.card:hover, .ui.violet.cards > .card:hover, .dashboard-card-link:hover {
  box-shadow: 0 0 0 1px #d4d4d5, 0 2px 0 0 #e83c6e, 0 1px 3px 0 #bcbdbd;
  cursor: pointer;
  z-index: 5;
  background: #fff;
  border: none;
  transform: translateY(-3px);
}
